import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Grid,
    Typography,
    List,
    ListItem,
    ListItemText,
    Checkbox,
    IconButton,
    Box,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

import ColorPicker from './tack/ColorPicker';

const IdeaForm = ({ open, onClose, onSave, availableTacks, onAddNewTack, onEditTack, initialData }) => {
    const [ideaName, setIdeaName] = useState('');
    const [description, setDescription] = useState('');
    const [selectedTacks, setSelectedTacks] = useState([]);
    const [tacks, setTacks] = useState([]);

    const [creatingTack, setCreatingTack] = useState(false);
    const [newTack, setNewTack] = useState('');
    const [newTackColor, setNewTackColor] = useState('#000000');
    const [newTackFontColor, setNewTackFontColor] = useState('#ffffff');
    const [editingTack, setEditingTack] = useState(null);

    // Este useEffect se dispara cuando recibimos initialData, es decir, cuando estamos en modo edición.
    useEffect(() => {
        if (initialData) {
            setIdeaName(initialData.titulo);
            setDescription(initialData.descripcion);
            setSelectedTacks(initialData.tacks.map(t => t.id));  // Selecciona los tacks asociados
            //setTacks([...tacks, ...initialData.tacks]);
            setTacks(tacks);
        } else {
            // Limpia el formulario cuando no hay initialData (modo de creación)
            setIdeaName('');
            setDescription('');
            setSelectedTacks([]);
            setTacks(availableTacks);
        }
    }, [initialData, availableTacks]);

    const handleSave = () => {

        if (!ideaName.trim()) {
            alert('Please enter a name for idea.');
            return;
        }

        if (!description.trim()) {
            alert('Please enter a description for idea.');
            return;
        }

        const ideaData = {
            id: initialData?.id,  // Incluimos el ID si estamos en modo de edición
            ideaName,
            description,
            tacks: selectedTacks.map(id => tacks.find(t => t.id === id))
        };

        onSave(ideaData);
        setIdeaName('');
        setDescription('');
        setSelectedTacks([]);
    };

    const handleToggleTack = (tack) => {
        const currentIndex = selectedTacks.indexOf(tack.id);
        const newSelectedTacks = [...selectedTacks];

        if (currentIndex === -1) {
            newSelectedTacks.push(tack.id);
        } else {
            newSelectedTacks.splice(currentIndex, 1);
        }

        setSelectedTacks(newSelectedTacks);
    };

    const handleCreateTack = async () => {        

        if (!newTack.trim()) {
            alert('Please enter a description for the Tag.');
            return;
        }

        let tackData = {
            descripcion: newTack,
            color: newTackColor,
            font_color: newTackFontColor
        }

        await onAddNewTack(tackData, (response) => {
            // Aquí puedes manejar la respuesta de componente B
            if (response.success) {
                setSelectedTacks(response.tacks.map(t => t.id));
                setTacks(response.tacks);
                setNewTack('');
                setNewTackColor('#000000');
                setNewTackFontColor('#ffffff');
                setCreatingTack(false)
            } else {
                // Maneja cualquier error o estado
            }
        });
    };

    const handleEditTack = (tack) => {
        setCreatingTack(true);
        setNewTack(tack.descripcion);
        setNewTackColor(tack.color);
        setNewTackFontColor(tack.font_color);
        setEditingTack(tack);
    };

    const saveTack = async () => {   
        if (!newTack.trim()) {
            alert('Please enter a description for the Tag.');
            return;
        }     

        let tackData = {
            descripcion: newTack,
            color: newTackColor,
            font_color: newTackFontColor
        }

        await onEditTack(editingTack.id, tackData, (response) => {
            // Aquí puedes manejar la respuesta de componente B
            if (response.success) {
                setSelectedTacks(response.tacks.map(t => t.id));
                setTacks(response.tacks);
                setNewTack('');
                setNewTackColor('#000000');
                setNewTackFontColor('#ffffff');
                setCreatingTack(false)             
            } else {
                // Maneja cualquier error o estado
            }
        });
    }

    const handleCancelTackCreation = () => {
        setCreatingTack(false);
        setNewTack('');
        setNewTackColor('#000000');
        setNewTackFontColor('#ffffff');
        setEditingTack(null);
    };

    const handleColorSelect = (color, font_color) => {
        setNewTackColor(color);
        setNewTackFontColor(font_color);
    };

    const handleTackChange = (event) => {
        setSelectedTacks(event.target.value);
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
            <DialogTitle>{initialData ? 'Editar Idea' : 'Nueva Idea'}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={9}>
                        <TextField
                            label="Nombre de la Idea"
                            fullWidth
                            margin="normal"
                            value={ideaName}
                            onChange={(e) => setIdeaName(e.target.value)}
                            required
                        />
                        <TextField
                            label="Descripción"
                            fullWidth
                            margin="normal"
                            multiline
                            rows={10}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                        />
                    </Grid>
                    {/* Sección secundaria - 2 columnas */}
                    <Grid item xs={3}>
                        <Typography variant="h6"> Tags </Typography>
                        {creatingTack ? (
                            <>
                                <TextField
                                    autoFocus
                                    fullWidth
                                    label={editingTack ? 'Edit Tag' : 'New Tag'}
                                    value={editingTack ? newTack : newTack}
                                    onChange={(e) => setNewTack(e.target.value)}
                                    required
                                />
                                <ColorPicker onColorSelect={handleColorSelect} selectedColorEdit={editingTack ? { newTackColor, newTackFontColor } : null} />
                                <Button onClick={editingTack ? saveTack : handleCreateTack } variant="contained" color="primary" sx={{ mt: 1 }}>
                                    {editingTack ? 'Update' : 'Create'}
                                </Button>
                                <Button onClick={handleCancelTackCreation} sx={{ mt: 1 }}>
                                    Cancelar
                                </Button>
                            </>
                        ) : (
                            <>
                                <List>
                                    {tacks.map((tack) => (
                                        <ListItem
                                            key={tack.id}
                                            disablePadding
                                            sx={{
                                                marginBottom: '1px',
                                                padding: '1px',
                                                borderRadius: '1px',
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Checkbox
                                                checked={selectedTacks.indexOf(tack.id) !== -1}
                                                onChange={() => handleToggleTack(tack)}
                                                sx={{ color: '#494a4a' }}
                                            />
                                            <Box
                                                sx={{
                                                    backgroundColor: tack.color,
                                                    padding: '4px',
                                                    borderRadius: '2px',
                                                    flexGrow: 1,
                                                    marginLeft: '4px'
                                                }}
                                            >
                                                <Typography style={{ color: tack.font_color, fontSize: '12px' }}>{tack.descripcion}</Typography>
                                                {/*<ListItemText
                                                    primary={tack.title}
                                                    primaryTypographyProps={{ style: { color: '#fff' } }}
                                                />*/}
                                            </Box>
                                            <IconButton onClick={() => handleEditTack(tack)} sx={{ color: '#494a4a' }}>
                                                <EditIcon fontSize="small" />
                                            </IconButton>
                                        </ListItem>
                                    ))}
                                </List>
                                <Button onClick={() => setCreatingTack(true)} startIcon={<AddIcon />} variant="outlined" fullWidth>
                                    Crear nuevo Tag
                                </Button>
                            </>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancelar
                </Button>
                <Button onClick={handleSave} color="primary">
                    Guardar Idea
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default IdeaForm;
