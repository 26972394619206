import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Container,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Paper,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Snackbar,
    Alert
} from '@mui/material';
import {
    fetchInvestigaciones, crearInvestigacion,
    actualizarInvestigacion, eliminarInvestigacion
} from '../services/investigacionService';

import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

function MostrarInvestigaciones() {

    const [investigaciones, setInvestigaciones] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [currentInvestigationId, setCurrentInvestigationId] = useState(null);
    const [newInvestigation, setNewInvestigation] = useState({
        titulo: '',
        descripcion: ''
    });

    const navigate = useNavigate();

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [investigacionSeleccionada, setInvestigacionSeleccionada] = useState(null);

    useEffect(() => {

        cargarInvestigaciones();

    }, []);

    const cargarInvestigaciones = async () => {
        const token = localStorage.getItem('token');

        try {
            const response = await fetchInvestigaciones(token);

            const data = await response;
            if (data && data.length > 0) {
                setInvestigaciones(data);
            } else {
                // Si no hay investigaciones, establece el estado con un array vacío y muestra un mensaje informativo
                setInvestigaciones([]);
                setSnackbarMessage('No se encontraron investigaciones para este usuario.');
                setSnackbarOpen(true);
            }
            //setInvestigaciones(data);      
        } catch (error) {
            //console.log('error:....', error)
            //navigate('/')
            console.error('Error al cargar las investigaciones:', error);
            setErrorMessage('Error al cargar las investigaciones. Por favor, intente nuevamente.');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewInvestigation({ ...newInvestigation, [name]: value });
    };

    const handleCreateInvestigation = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await crearInvestigacion(token, newInvestigation);
            if (response && response.message === 'Investigación creada correctamente.') {
                await cargarInvestigaciones();
                setSnackbarMessage('Investigación creada correctamente');
                setSnackbarOpen(true); // Mostrar el Snackbar        
                handleCloseModal();
            } else {
                setErrorMessage(response.message || 'Error al crear la investigación');
            }
        } catch (error) {
            setErrorMessage('Error al crear la investigación. Por favor, intente nuevamente.');
        }
    };

    const handleUpdateInvestigation = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await actualizarInvestigacion(token, currentInvestigationId, newInvestigation);
            if (response && response.message === 'Investigación actualizada correctamente.') {
                await cargarInvestigaciones();
                setSnackbarMessage('Investigación actualizada correctamente');
                setSnackbarOpen(true); // Mostrar el Snackbar        
                handleCloseModal();
            } else {
                setErrorMessage(response.message || 'Error al actualizar la investigación');
            }
        } catch (error) {
            setErrorMessage('Error al actualizar la investigación. Por favor, intente nuevamente.');
        }
    };

    const clearForm = () => {
        setNewInvestigation({
            titulo: '',
            descripcion: ''
        });
        setCurrentInvestigationId(null);
    };

    const handleOpenModal = () => {
        clearForm();
        setIsEditing(false);
        setOpenModal(true);
    }

    const handleOpenEditModal = (investigacion) => {
        setNewInvestigation({
            titulo: investigacion.titulo,
            descripcion: investigacion.descripcion
        });
        setCurrentInvestigationId(investigacion.id);
        setIsEditing(true);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        clearForm();
        setOpenModal(false);
    }

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleConfirmDelete = async () => {
        if (investigacionSeleccionada) {
            const token = localStorage.getItem('token');
            try {
                const response = await eliminarInvestigacion(token, investigacionSeleccionada.id);
                if (response && response.message === 'Investigación eliminada correctamente.') {
                    await cargarInvestigaciones();
                    setSnackbarMessage('Investigación eliminada correctamente');
                    setSnackbarOpen(true); // Mostrar el Snackbar
                } else {
                    setErrorMessage(response.message || 'Error al eliminar la investigación');
                }
            } catch (error) {
                setErrorMessage('Error al eliminar la investigación. Por favor, intente nuevamente.');
            } finally {
                setConfirmDeleteOpen(false);
            }
        }
    };

    // Funciones para manejar las acciones
    const onEdit = (investigacion) => {
        handleOpenEditModal(investigacion);
        //console.log(`Editar investigación con ID: ${investigacion}`);
        // Aquí podrías abrir una ventana modal para editar la investigación
    };

    const onView = (id) => {
        navigate(`/investigacion/${id}/articulos`);
        // Aquí podrías redirigir a la página de detalle de la investigación
    };

    const onDelete = (investigacion) => {
        setInvestigacionSeleccionada(investigacion);
        setConfirmDeleteOpen(true);
    };

    return (
        <Container maxWidth="md" sx={{ mt: 4 }}>
            <Typography variant="h4" component="h1" gutterBottom>
                Mis Investigaciones
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleOpenModal}
                    sx={{ float: 'right' }}
                >
                    Nueva Investigación
                </Button>
            </Typography>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="tabla de investigaciones">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Título</TableCell>
                            <TableCell>Descripción</TableCell>
                            <TableCell>Fecha de Creación</TableCell>
                            <TableCell>Estado</TableCell>
                            <TableCell>Accion</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {investigaciones.map((investigacion) => (
                            <TableRow key={investigacion.id}>
                                <TableCell>{investigacion.id}</TableCell>
                                <TableCell>{investigacion.titulo}</TableCell>
                                <TableCell>{investigacion.descripcion}</TableCell>
                                <TableCell>{investigacion.fecha_creacion}</TableCell>
                                <TableCell>{investigacion.EstReg === 'A' ? 'Activo' : 'Eliminado'}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => onEdit(investigacion)}>
                                        <EditIcon color="primary" />
                                    </IconButton>
                                    <IconButton onClick={() => onView(investigacion.id)}>
                                        <VisibilityIcon color="action" />
                                    </IconButton>
                                    <IconButton onClick={() => onDelete(investigacion)}>
                                        <DeleteIcon color="error" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Ventana Modal para Crear Nueva Investigación */}
            <Dialog open={openModal} onClose={handleCloseModal}>
                <DialogTitle>{isEditing ? 'Editar Investigación' : 'Nueva Investigación'}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Título"
                        type="text"
                        fullWidth
                        name="titulo"
                        value={newInvestigation.titulo}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        label="Descripción"
                        type="text"
                        fullWidth
                        name="descripcion"
                        value={newInvestigation.descripcion}
                        onChange={handleInputChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="secondary">
                        Cancelar
                    </Button>
                    <Button onClick={isEditing ? handleUpdateInvestigation : handleCreateInvestigation} color="primary">
                        {isEditing ? 'Actualizar' : 'Crear'}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Ventana Modal de Confirmación de Eliminación */}
            <Dialog open={confirmDeleteOpen} onClose={() => setConfirmDeleteOpen(false)}>
                <DialogTitle>Confirmar Eliminación</DialogTitle>
                <DialogContent>
                    <Typography>¿Está seguro que desea eliminar la investigación "{investigacionSeleccionada?.titulo}"?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDeleteOpen(false)} color="secondary">
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleConfirmDelete}
                        color="primary"
                        variant="contained"
                    >
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar para mostrar mensajes */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={errorMessage ? "error" : "success"} // Cambia la severidad según el mensaje
                    sx={{ width: '100%' }}
                >
                    {errorMessage || snackbarMessage}  {/* Muestra el mensaje de error o el mensaje de éxito */}
                </Alert>
            </Snackbar>
        </Container>
    );
}

export default MostrarInvestigaciones;
