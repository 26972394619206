import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  Box,
  Stack,
  Grid,
  Pagination,
  TextField,
  Slide
} from '@mui/material';

import { styled } from '@mui/material/styles';

import ArticuloForm from './ArticuloForm';
import IdeaForm from './IdeaForm';

import {
  fetchArticulosPorInvestigacion,
  crearArticulo,
  actualizarArticulo,
  eliminarArticulo
} from '../services/articuloService';

import {
  fetchTacksServices,
  createTackService,
  editTackService
} from '../services/tackServices';

import {
  createIdea,
  editIdea,
  deleteIdea
} from '../services/ideaServices';


import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PlaylistAdd from '@mui/icons-material/PlaylistAdd';

function MostrarArticulosPorInvestigacion() {

  const { id } = useParams(); // id de la investigación

  const [errorMessage, setErrorMessage] = useState('');
  //==PAGINACION DE ARTICULOS====
  const [filteredArticulos, setFilteredArticulos] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [articlesPerPage] = useState(10); // Mostrar 10 artículos por página
  const [searchTerm, setSearchTerm] = useState('');
  const [currentArticles, setCurrentArticles] = useState([]); // Estado para los artículos visibles

  //==FOR ARTICULO===
  const [articulos, setArticulos] = useState([]);
  const [currentArticuloId, setCurrentArticuloId] = useState(null);
  const [expandedArticleId, setExpandedArticleId] = useState(null);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [articuloSeleccionado, setArticuloSeleccionado] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  //==FOR IDEA===
  const [isEditingIdea, setIsEditingIdea] = useState(false);
  const [currentIdeaId, setCurrentIdeaId] = useState(null);
  const [ideaModalOpen, setIdeaModalOpen] = useState(false);
  const [ideaData, setIdeaData] = useState(null);

  const [confirmDeleteIdeaOpen, setConfirmDeleteIdeaOpen] = useState(false);
  const [selectIdea, setSelectIdea] = useState(null);

  const [availableTacks, setAvailableTacks] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');


  useEffect(() => {
    cargarArticulos();
    cargarTacks();
  }, [id]);

  /* =====Busquedas de articulos==== */
  // Filtrar los artículos según el término de búsqueda
  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);

    const filtered = articulos.filter(articulo =>
      articulo.titulo.toLowerCase().includes(searchTerm)
    );

    setFilteredArticulos(filtered);
    setCurrentArticles(filtered.slice(0, articlesPerPage)); // Reiniciar paginación
    setCurrentPage(1); // Resetear a la primera página cuando se realiza una búsqueda
  };

  // Cambiar la página actual
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    const indexOfLastArticle = value * articlesPerPage;
    const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
    setCurrentArticles(filteredArticulos.slice(indexOfFirstArticle, indexOfLastArticle));
  };

  /* =====FIN==== */

  const cargarArticulos = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetchArticulosPorInvestigacion(token, id);
      if (response && response.length > 0) {
        console.log('mis articulos', response)
        setArticulos(response);
        setFilteredArticulos(response); // Iniciar con todos los artículos visibles
        setCurrentArticles(response.slice(0, articlesPerPage)); // Iniciar con los primeros artículos paginados      
      } else {
        setArticulos([]);
        setSnackbarMessage('No se encontraron artículos para esta investigación.');
        setSnackbarOpen(true);
      }

    } catch (error) {
      console.error('Error al cargar los artículos:', error);
      setErrorMessage('Error al cargar los artículos. Por favor, intente nuevamente.');
    }
  };

  const cargarTacks = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetchTacksServices(token, id);
      if (response && response.length > 0) {
        setAvailableTacks(response);
        return response;
      } else {
        setAvailableTacks([]);
        return [];
      }
    } catch (error) {
      console.error('Error al cargar los tacks:', error);
      setErrorMessage('Error al cargar los tacks. Por favor, intente nuevamente.');
      return null;
    }
  };

  const handleCreateArticulo = async (articuloData) => {
    const token = localStorage.getItem('token');
    try {
      const response = await crearArticulo(token, id, articuloData);
      if (response && response.message === 'Artículo creado correctamente.') {

        await cargarArticulos();
        setSnackbarOpen(true);
        handleCloseModal();
        setSnackbarMessage('Artículo creado correctamente');
      } else {
        setErrorMessage(response.message || 'Error al crear el artículo');
      }
    } catch (error) {
      setErrorMessage('Error al crear el artículo. Por favor, intente nuevamente.');
    }
  };

  const handleUpdateArticulo = async (articuloData) => {
    //console.log('Editar articulo...', articuloData)
    const token = localStorage.getItem('token');
    try {
      const response = await actualizarArticulo(token, currentArticuloId, articuloData);
      if (response && response.message === 'Artículo actualizado correctamente.') {
        await cargarArticulos();
        setSnackbarMessage('Artículo actualizado correctamente');
        setSnackbarOpen(true);
        handleCloseModal();
      } else {
        setErrorMessage(response.message || 'Error al actualizar el artículo');
      }
    } catch (error) {
      setErrorMessage('Error al actualizar el artículo. Por favor, intente nuevamente.');
    }
  };

  //================ Ideas =============

  const handleToggleIdeas = (articuloID) => {
    setExpandedArticleId(expandedArticleId === id ? null : articuloID);
  };

  const handleCloseIdeas = () => {
    setExpandedArticleId(null);
  }

  const handleCreateIdea = (articuloID) => {
    setIsEditingIdea(false);
    setIdeaModalOpen(true);
    setIdeaData(null);
    setCurrentArticuloId(articuloID)
    setExpandedArticleId(expandedArticleId === id ? null : articuloID);
  };

  const handleOpenEditIdea = (articuloId, ideaId) => {

    let tempArticulo = articulos.find(articulo => articulo.id === articuloId);

    if (tempArticulo) {
      // Paso 2: Encontrar la idea con id = 1 dentro del artículo
      let dataIdea = tempArticulo.ideas.find(idea => idea.id === ideaId);
      setCurrentArticuloId(articuloId)
      setIsEditingIdea(true);
      setCurrentIdeaId(ideaId);
      setIdeaData(dataIdea);
      setIdeaModalOpen(true);
    } else {

    }
  };

  const handleSaveIdea = async (ideaData) => {
    const token = localStorage.getItem('token');
    ideaData['id_articulo'] = currentArticuloId;

    if (isEditingIdea) {
      if (currentIdeaId === ideaData.id && currentArticuloId === ideaData.id_articulo) {

        const tackIds = ideaData.tacks.map(tack => tack.id);
        const updatedIdeaData = {
          ...ideaData,
          tacks: tackIds // Solo los IDs
        };
        try {
          const response = await editIdea(token, currentIdeaId, updatedIdeaData);

          if (response.success) {
            setSnackbarMessage('Idea Editada correctamente');
            setSnackbarOpen(true);

            // Esperar a que los artículos se carguen
            await cargarArticulos();

            const articleFound = articulos.find(article => article.id === currentArticuloId);
            if (articleFound) {
              // Ahora expandimos las ideas del artículo
              handleToggleIdeas(currentArticuloId);
            } else {
              console.error('El artículo no fue encontrado en el estado después de cargar los artículos.');
            }
            handleCloseIdeaModal();
          } else {
            console.error(response.message);
            setErrorMessage(response.message);
          }
        } catch (error) {
          console.error('Error inesperado al editar la idea:', error);
          setErrorMessage('Ocurrió un error inesperado. Inténtalo de nuevo.');
        }
      }
    } else {
      try {
        // Llamar al servicio `createIdea` pasando el token y los datos de la idea
        const response = await createIdea(token, ideaData);

        if (response.success) {
          setSnackbarMessage('Idea creada correctamente');
          setSnackbarOpen(true);

          // Esperar a que los artículos se carguen
          await cargarArticulos();

          // Asegurarnos de que el artículo está disponible en el estado antes de expandir
          const articleFound = articulos.find(article => article.id === ideaData.id_articulo);
          if (articleFound) {
            // Ahora expandimos las ideas del artículo
            handleToggleIdeas(ideaData.id_articulo);
          } else {
            console.error('El artículo no fue encontrado en el estado después de cargar los artículos.');
          }

          // Cerrar el modal
          handleCloseIdeaModal();
        } else {
          console.error(response.message);
          setErrorMessage(response.message);
        }
      } catch (error) {
        console.error('Error inesperado al crear la idea:', error);
        setErrorMessage('Ocurrió un error inesperado. Inténtalo de nuevo.');
      }
    }
  };

  const handleCloseIdeaModal = () => {
    setIsEditingIdea(false);
    setIdeaModalOpen(false);
    setCurrentIdeaId(null);
    setIdeaData(null);
    //setExpandedArticleId(null);
  };

  const handleDeleteIdea = (idea) => {
    setSelectIdea(idea)
    setConfirmDeleteIdeaOpen(true);
  }

  const handleConfirmDeleteIdea = async () => {
    if (selectIdea) {
      const token = localStorage.getItem('token');
      try {
        const response = await deleteIdea(token, selectIdea.id);
        if (response && response.message === 'Idea eliminada correctamente.') {
          await cargarArticulos();
          setSnackbarMessage('Idea eliminada correctamente');
          setSnackbarOpen(true); // Mostrar el Snackbar
        } else {
          setErrorMessage(response.message || 'Error al eliminar la idea');
        }
      } catch (error) {
        setErrorMessage('Error al eliminar la idea. Por favor, intente nuevamente.');
      } finally {
        setConfirmDeleteIdeaOpen(false);
      }
    }
  }

  //======= Fin Ideas =========  

  const handleAddNewTack = async (tackData, callback) => {
    const token = localStorage.getItem('token');
    try {
      const response = await createTackService(token, tackData);
      if (response && response.message === 'Tack creado correctamente.') {
        const tacks = await cargarTacks(); // Obtener los tacks directamente
        if (tacks !== null) { // Asegurarse de que no haya ocurrido un error en cargarTacks
          setSnackbarMessage('Tag creado correctamente');
          setSnackbarOpen(true);
          setAvailableTacks(tacks);
          callback({ success: true, tacks }); // Usar los tacks cargados directamente
        } else {
          callback({ success: false, error: 'Error al cargar los tags después de la creación.' });
        }
      } else {
        const errorMsg = response.message || 'Error al crear el tag';
        setErrorMessage(errorMsg);
        callback({ success: false, error: errorMsg });
      }
    } catch (error) {
      console.error('Error en handleAddNewTack:', error);
      setErrorMessage('Error al crear el tag. Por favor, intente nuevamente.');
      callback({ success: false, error: error.message });
    }
  };

  const editTack = async (tack_id, tackData, callback) => {

    const token = localStorage.getItem('token');
    try {
      const response = await editTackService(token, tack_id, tackData);
      if (response && response.message === 'Tack editado correctamente.') {
        const tacks = await cargarTacks(); // Obtener los tacks directamente
        if (tacks !== null) { // Asegurarse de que no haya ocurrido un error en cargarTacks
          setSnackbarMessage('Tag editado correctamente');
          setSnackbarOpen(true);
          setAvailableTacks(tacks);
          callback({ success: true, tacks }); // Usar los tacks cargados directamente
        } else {
          callback({ success: false, error: 'Error al cargar los tags después de la creación.' });
        }
      } else {
        const errorMsg = response.message || 'Error al editar el tag';
        setErrorMessage(errorMsg);
        callback({ success: false, error: errorMsg });
      }
    } catch (error) {
      console.error('Error en editTack:', error);
      setErrorMessage('Error al editar el tag. Por favor, intente nuevamente.');
      callback({ success: false, error: error.message });
    }

  }

  const clearForm = () => {
    setCurrentArticuloId(null);
  };

  const handleOpenModal = () => {
    clearForm();
    setIsEditing(false);
    setOpenModal(true);
  }

  const handleOpenEditModal = (articulo) => {
    setCurrentArticuloId(articulo.id);
    setIsEditing(true);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    clearForm();
    setOpenModal(false);
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleConfirmDelete = async () => {
    if (articuloSeleccionado) {
      const token = localStorage.getItem('token');
      try {
        const response = await eliminarArticulo(token, articuloSeleccionado.id);
        if (response && response.message === 'Artículo eliminado correctamente.') {
          await cargarArticulos();
          setSnackbarMessage('Artículo eliminado correctamente');
          setSnackbarOpen(true);
        } else {
          setErrorMessage(response.message || 'Error al eliminar el artículo');
        }
      } catch (error) {
        setErrorMessage('Error al eliminar el artículo. Por favor, intente nuevamente.');
      } finally {
        setConfirmDeleteOpen(false);
      }
    }
  };

  const handleSubmit = (articuloData) => {
    if (isEditing) {
      handleUpdateArticulo(articuloData);
    } else {
      handleCreateArticulo(articuloData);
    }
  };

  // Función para ver detalles del artículo
  const handleViewDetails = (articulo) => {
    setSelectedArticle(articulo);
  };

  // Funciones para manejar las acciones
  const onEdit = (articulo) => {
    handleOpenEditModal(articulo);
  };

  const onDelete = (articulo) => {
    setArticuloSeleccionado(articulo);
    setConfirmDeleteOpen(true);
  };

  const baseStyle = {
    backgroundColor: '#fefefe',
    borderRadius: '8px',
    marginBottom: '16px',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
    width: '100%'
  };

  const selectedStyle = {
    ...baseStyle,
    border: '2px solid #1976d2', // Resalta el artículo seleccionado
    width: '100%'
  };

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    padding: theme.spacing(2), // Añadimos padding para dar espacio alrededor del contenido
    display: 'flex',
    flexDirection: 'column', // Alinear contenido en columna
    justifyContent: 'center', // Alinear verticalmente al centro si es necesario
    gap: theme.spacing(2), // Espaciado entre elementos
    alignItems: 'flex-start', // Alineamos los elementos al inicio
  }));

  return (
    <Container maxWidth={false} disableGutters sx={{ mt: 4, width: '100%', maxWidth: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={8} style={{ height: '95vh', overflowY: 'auto' }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Artículos de la Investigación
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleOpenModal}
              sx={{ float: 'right' }}
            >
              Nuevo Artículo
            </Button>
          </Typography>
          <TextField
            label="Buscar por título"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearch}
            sx={{ mb: 2, width: '100%' }}
          />
          <TableContainer component={Paper} sx={{ width: '100%' }}>
            <Table sx={{ minWidth: 650 }} aria-label="tabla de artículos">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '3%' }}>ID</TableCell>
                  <TableCell sx={{ width: '77%' }}>Título</TableCell>
                  <TableCell sx={{ width: '20%' }}>Acción</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentArticles.map((articulo, index) => (
                  <React.Fragment key={index}>
                    <TableRow
                      key={articulo.id}
                      sx={expandedArticleId === articulo.id ? selectedStyle : baseStyle}
                    >
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '1.0rem', color: '#333' }}>
                        {index + 1}
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '1.0rem', color: '#333' }}>
                        {articulo.titulo}
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Editar Articulo">
                          <IconButton onClick={() => onEdit(articulo)}>
                            <EditIcon color="primary" />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Delete Article">
                          <IconButton onClick={() => onDelete(articulo)}>
                            <DeleteIcon color="error" />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Add idea">
                          <IconButton onClick={() => handleCreateIdea(articulo.id)}>
                            <PlaylistAdd color="primary" />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title={expandedArticleId === articulo.id ? 'Close Ideas' : 'Show Ideas'}>
                          <IconButton onClick={() => expandedArticleId === articulo.id ? handleCloseIdeas() : handleToggleIdeas(articulo.id)}>
                            <ExpandMore />
                          </IconButton>
                        </Tooltip>

                        <Button onClick={() => handleViewDetails(articulo)}>
                          Article Details
                        </Button>
                      </TableCell>
                    </TableRow>
                    {expandedArticleId === articulo.id && (
                      <TableRow>
                        <TableCell colSpan={5}>
                          <Box
                            sx={{
                              p: 1,
                              borderRadius: 2,
                              bgcolor: 'background.default',
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 1,
                            }}
                          >
                            {articulo.ideas.map((idea) => (
                              <Item
                                key={idea.id}
                                elevation={8}
                              >
                                <Grid container spacing={2}>
                                  <Grid item xs={10}>

                                    {/* Título de la idea */}
                                    <Typography variant="h6" sx={{ marginBottom: '8px', color: '#333' }}>
                                      {idea.titulo}
                                    </Typography>
                                    {/* Descripción de la idea */}
                                    <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap', lineHeight: 1.6, marginBottom: '8px', color: '#666' }}>
                                      {idea.descripcion}
                                    </Typography>
                                    {/* Tacks asociados */}
                                    <div>
                                      {idea.tacks.map((tack) => (
                                        <Button
                                          key={tack.id}
                                          variant="contained"
                                          size="small"
                                          sx={{
                                            backgroundColor: tack.color,
                                            color: tack.font_color,
                                            marginRight: '8px',
                                            marginBottom: '8px',
                                            textTransform: 'none',
                                            borderRadius: '16px',
                                            padding: '4px 12px',
                                            fontSize: '0.875rem',
                                          }}
                                        >
                                          {tack.descripcion}
                                        </Button>
                                      ))}
                                    </div>
                                  </Grid>
                                  {/* Botones Editar y Eliminar */}
                                  <Grid item xs={2} style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center', // Alinear verticalmente al centro
                                    alignItems: 'center', //
                                  }}>
                                    <Stack direction="column" spacing={2} alignItems="center">
                                      <Tooltip title="Edit Idea">
                                        <IconButton onClick={() => handleOpenEditIdea(articulo.id, idea.id)}>
                                          <EditIcon color="primary" />
                                        </IconButton>
                                      </Tooltip>

                                      <Tooltip title="Delete Idea">
                                        <IconButton onClick={() => handleDeleteIdea(idea)}>
                                          <DeleteIcon color="error" />
                                        </IconButton>
                                      </Tooltip>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </Item>
                            ))}
                          </Box>
                        </TableCell>

                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Paginación */}
          <Pagination
            count={Math.ceil(filteredArticulos.length / articlesPerPage)} // Calcular el número de páginas
            page={currentPage}
            onChange={handlePageChange}
            sx={{ mt: 2 }}
          />

          {/* Ventana Modal para Crear/Editar Artículo */}
          <Dialog
            open={openModal}
            onClose={handleCloseModal}
            fullWidth
            disableEnforceFocus
          >
            <DialogTitle>{isEditing ? 'Editar Artículo' : 'Nuevo Artículo'}</DialogTitle>
            <ArticuloForm
              initialData={isEditing ? articulos.find(a => a.id === currentArticuloId) : {}}
              onSubmit={handleSubmit}
              onCancel={handleCloseModal} // Añadimos el onCancel aquí
            />
          </Dialog>

          {/* Ventana Modal para Crear Idea */}
          <IdeaForm
            open={ideaModalOpen}
            onClose={handleCloseIdeaModal}
            onSave={handleSaveIdea}
            onAddNewTack={handleAddNewTack}
            onEditTack={editTack}
            availableTacks={availableTacks}
            initialData={isEditingIdea ? ideaData : null}
          />

          {/* Ventana Modal de Confirmación de Eliminación */}
          <Dialog open={confirmDeleteOpen} onClose={() => setConfirmDeleteOpen(false)}>
            <DialogTitle>Confirmar Eliminación</DialogTitle>
            <DialogContent>
              <Typography>¿Está seguro que desea eliminar el artículo "{articuloSeleccionado?.titulo}"?</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setConfirmDeleteOpen(false)} color="secondary">
                Cancelar
              </Button>
              <Button
                onClick={handleConfirmDelete}
                color="primary"
                variant="contained"
              >
                Eliminar
              </Button>
            </DialogActions>
          </Dialog>

          {/* Ventana Modal de Confirmación de Eliminación */}
          <Dialog open={confirmDeleteIdeaOpen} onClose={() => setConfirmDeleteIdeaOpen(false)}>
            <DialogTitle>Confirmar Eliminación</DialogTitle>
            <DialogContent>
              <Typography>¿Está seguro que desea eliminar la idea "{selectIdea?.titulo}"?</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setConfirmDeleteIdeaOpen(false)} color="secondary">
                Cancelar
              </Button>
              <Button
                onClick={handleConfirmDeleteIdea}
                color="primary"
                variant="contained"
              >
                Eliminar
              </Button>
            </DialogActions>
          </Dialog>

          {/* Snackbar para mostrar mensajes */}
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity={errorMessage ? "error" : "success"}
              sx={{ width: '100%' }}
            >
              {errorMessage || snackbarMessage}
            </Alert>
          </Snackbar>
        </Grid>
        <Grid item xs={4} style={{ position: 'sticky', top: '20px' }}>
          <Slide direction="left" in={Boolean(selectedArticle)} mountOnEnter unmountOnExit>
            <Paper elevation={4} sx={{ padding: 3, borderRadius: 2 }}>
              {selectedArticle && (
                <>
                  {/* Título del artículo */}
                  <Typography variant="h5" fontWeight="bold" gutterBottom>
                    {selectedArticle.titulo}
                  </Typography>

                  {/* Autor y DOI */}
                  <Typography variant="body2" gutterBottom>
                    <strong>Autor:</strong> {selectedArticle.autor || 'Desconocido'}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <strong>DOI:</strong> {selectedArticle.doi || 'No disponible'}
                  </Typography>

                  {/* Fecha de publicación */}
                  <Typography variant="body2" gutterBottom>
                    <strong>Fecha de Publicación:</strong> {selectedArticle.fecha_publicacion ? new Date(selectedArticle.fecha_publicacion).toLocaleDateString() : 'No publicada'}
                  </Typography>

                  {/* Idioma y estado de revisión */}
                  <Typography variant="body2" gutterBottom>
                    <strong>Idioma:</strong> {selectedArticle.idioma || 'No especificado'}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <strong>Estado de Revisión:</strong> {selectedArticle.estado_revision || 'No especificado'}
                  </Typography>

                  {/* Información de la publicación */}
                  <Typography variant="body2" gutterBottom>
                    <strong>Nombre de la Publicación:</strong> {selectedArticle.nombre_publicacion || 'No disponible'}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <strong>Volumen:</strong> {selectedArticle.volumen || 'N/A'},
                    <strong> Número:</strong> {selectedArticle.numero || 'N/A'}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <strong>Páginas:</strong> {selectedArticle.paginas || 'N/A'}
                  </Typography>

                  {/* Palabras clave */}
                  {selectedArticle.palabras_clave && (
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="body2" gutterBottom><strong>Palabras Clave:</strong></Typography>
                      <ul>
                        {selectedArticle.palabras_clave.split(',').map((palabra, index) => (
                          <li key={index}>
                            <Typography variant="body2">{palabra.trim()}</Typography>
                          </li>
                        ))}
                      </ul>
                    </Box>
                  )}

                  {/* Abstract con formato de texto largo */}
                  <Box sx={{ mt: 3, p: 2, backgroundColor: '#f9f9f9', borderRadius: 2 }}>
                    <Typography variant="body2" gutterBottom fontWeight="bold">
                      Resumen:
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      sx={{ whiteSpace: 'pre-wrap', lineHeight: 1.6 }}
                    >
                      {selectedArticle.abstract || 'No disponible'}
                    </Typography>
                  </Box>

                  {/* Botón para ver el PDF si está disponible */}
                  {selectedArticle.ruta_pdf || selectedArticle.link ? (
                    <Button
                      variant="contained"
                      color="primary"
                      href={selectedArticle.ruta_pdf || selectedArticle.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ mt: 2 }}
                    >
                      Ver PDF
                    </Button>
                  ) : (
                    <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                      PDF no disponible
                    </Typography>
                  )}
                </>
              )}
            </Paper>
          </Slide>
        </Grid>

      </Grid>
    </Container>
  );
}

export default MostrarArticulosPorInvestigacion;
