import React from 'react';
import { useNavigate } from 'react-router-dom';
import { List, ListItemText, ListItemButton, ListItemIcon } from '@mui/material';
import InboxIcon from '@mui/icons-material/Inbox';
//import DraftsIcon from '@mui/icons-material/Drafts';

const LateralMenu = () => {
    const navigate = useNavigate();
   //const [selectedIndex, setSelectedIndex] = React.useState(1);

    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <List>
            <ListItemButton                
                onClick={() => handleNavigation('/investigaciones')}
            >
                <ListItemIcon>
                    <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Investigaciones" />
            </ListItemButton>

            <ListItemButton                
                onClick={() => handleNavigation('/articulos')}
            >
                <ListItemIcon>
                    <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Articulos" />
            </ListItemButton>

            <ListItemButton                
                onClick={() => handleNavigation('/ideas')}
            >
                <ListItemIcon>
                    <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Ideas" />
            </ListItemButton>

            <ListItemButton                
                onClick={() => handleNavigation('/tags')}
            >
                <ListItemIcon>
                    <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Tags" />
            </ListItemButton>            
        </List>
    );
};

export default LateralMenu;
