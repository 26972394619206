//const API_URL = 'http://localhost/INVESTIGACION/routes/api.php';
const API_URL = process.env.REACT_APP_API_URL;

export const fetchInvestigaciones = async (token) => {
    try {
        const response = await fetch(`${API_URL}/investigaciones`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });

        if (response.status === 204) {
            // Si la respuesta es 204, no hay contenido, por lo que devolvemos un array vacío
            return [];
        }

        if (response.status === 401) {
            //console.log('ENTRO AL 401')
            // Retornar un objeto que indique un error de autorización
            return { error: 'unauthorized' };
        }

        const data = await response.json();

        if (!response.ok) {
            throw new Error(data.message || 'Error al obtener las investigaciones');
        }

        return data;

    } catch (error) {
        console.error('Error fetching investigaciones:', error);
        throw error;
    }
};

export const crearInvestigacion = async (token, investigacionData) => {
    try {
        const response = await fetch(`${API_URL}/investigaciones`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(investigacionData),
        });
        //console.log(response)
        return await response.json();
    } catch (error) {
        console.error('Error creating investigacion:', error);
        throw error;
    }
};

export const actualizarInvestigacion = async (token, investigacionID, investigacionData) => {
    try {
        const response = await fetch(`${API_URL}/investigaciones/${investigacionID}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(investigacionData),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Error al actualizar la investigación');
        }

        return await response.json();
    } catch (error) {
        console.error('Error updating investigacion:', error);
        throw error;
    }
};

export const eliminarInvestigacion = async (token, investigacionID) => {
    try {
        const response = await fetch(`${API_URL}/investigaciones/${investigacionID}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Error al eliminar la investigación');
        }

        return await response.json();
    } catch (error) {
        console.error('Error eliminando investigación:', error);
        throw error;
    }
};
