import React from 'react';
import { AuthContext } from '../../context/AuthContext'; // No necesitas AuthProvider aquí
import {
    Menu,
    MenuItem,
    Avatar,
    IconButton
} from '@mui/material';

function UserMenu() {
    const { logout } = React.useContext(AuthContext); // Access the logout function from context
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                edge="end"
                color="inherit"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenuOpen}
            >
                <Avatar alt="User Avatar" src="/static/images/avatar/1.jpg" />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleMenuClose}>Mi cuenta</MenuItem>
                <MenuItem onClick={logout}>Cerrar sesión</MenuItem>
            </Menu>
        </>
    );
};

export default UserMenu;
