import React, { useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './context/AuthContext'; // Supongo que tienes un contexto para autenticación
import Login from './components/Login';
import Investigaciones from './components/MostrarInvestigaciones';
import ArticulosPorInvestigacion from './components/MostrarArticulosPorInvestigacion';

function App() {
  const { isAuthenticated } = useAuth(); // Obtener estado de autenticación
  const location = useLocation();
  const navigate = useNavigate();

  // Redirigir a /investigaciones si el usuario está autenticado y quiere acceder a /login
  useEffect(() => {
    if (isAuthenticated && (location.pathname === '/login' || location.pathname === '/')) {
      navigate('/investigaciones');
    }
  }, [isAuthenticated, location.pathname, navigate]);

  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      {/* Aquí renderiza el resto de tu aplicación o tus rutas */}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/investigaciones" element={<Investigaciones />} />
        <Route path="/investigacion/:id/articulos" element={<ArticulosPorInvestigacion />} />
        {/* Agrega más rutas según sea necesario */}
      </Routes>
    </div>
  );
}

export default App;
