//const API_URL = 'http://localhost/INVESTIGACION/routes/api.php';
const API_URL = process.env.REACT_APP_API_URL;

export const fetchIdeasPorArticulo = async (token, articuloID) => {    
    try {
        const response = await fetch(`${API_URL}/ideas?articulo=${articuloID}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });

        if (response.status === 204) {
            return [];
        }

        const data = await response.json();

        //console.log('MI DATA ES...', data)
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Error al obtener las ideas');
        }


        return data;
    } catch (error) {
        console.error('Error fetching ideas:', error);
        throw error;
    }
};

export const createIdea = async (token, ideaData) => {
    try {
        // Enviar toda la información de la idea y los tacks al backend en una única solicitud
        const response = await fetch(`${API_URL}/ideas`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id_articulo: ideaData.id_articulo, // Artículo relacionado
                titulo: ideaData.ideaName,         // Nombre de la idea
                descripcion: ideaData.description, // Descripción de la idea
                tacks: ideaData.tacks,             // Array de tacks a asociar
                fecha_creacion: new Date().toISOString(),
                EstReg: 'A'
            }),
        });

        // Manejo de la respuesta del backend
        if (!response.ok) {
            const errorResponse = await response.json();
            throw new Error(errorResponse.message || 'Error creando la idea y asociando los tags.');
        }

        const result = await response.json();

        return {
            success: true,
            message: result.message || 'Idea creada correctamente y tags asociados.',
        };
    } catch (error) {
        console.error('Error creando la idea:', error);
        return {
            success: false,
            message: error.message || 'Error inesperado al crear la idea.',
        };
    }
};

export const editIdea = async (token, ideaID, ideaData) => {
    try {
        // Enviar toda la información de la idea y los tacks al backend en una única solicitud
        //console.log('data in edit idea service..:',  ideaData)
        const response = await fetch(`${API_URL}/ideas/${ideaID}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id_articulo: ideaData.id_articulo, // Artículo relacionado
                titulo: ideaData.ideaName,         // Nombre de la idea
                descripcion: ideaData.description, // Descripción de la idea
                tacks: ideaData.tacks,            // Array de tacks a asociar                
                EstReg: 'A'
            }),
        });

        // Manejo de la respuesta del backend
        if (!response.ok) {
            const errorResponse = await response.json();
            throw new Error(errorResponse.message || 'Error al editar la idea con los tags.');
        }

        const result = await response.json();

        return {
            success: true,
            message: result.message || 'Idea editada correctamente y tags asociados.',
        };
    } catch (error) {
        console.error('Error editando la idea:', error);
        return {
            success: false,
            message: error.message || 'Error inesperado al editar la idea.',
        };
    }
};

export const deleteIdea = async (token, ideaID) => {
    //console.log('Idea a eliminar..', ideaID)
    try {
        const response = await fetch(`${API_URL}/ideas/${ideaID}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Error al eliminar la idea');
        }

        return await response.json();
    } catch (error) {
        console.error('Error eliminando idea:', error);
        throw error;
    }
};
