const API_URL = process.env.REACT_APP_API_URL;

export const fetchTacksServices = async (token) => {
    try {
        const response = await fetch(`${API_URL}/tacks`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });

        if (response.status === 204) {
            return [];
        }      
        
        if (response.status === 401) {
            return { error: 'unauthorized' };
        }

        const data = await response.json();

        //console.log('dta tacks...', data)

        if (!response.ok) {
            throw new Error(data.message || 'Error al obtener los Tags');        
        }

        return data;

    } catch (error) {
        console.error('Error fetching Tags:', error);
        throw error;
    }
};

export const createTackService = async (token, tackData) => {

    //console.log('Data llegando al servicio de crearTack', tackData);    
    try {
        const response = await fetch(`${API_URL}/tacks`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(tackData),
        });
        return await response.json();
    } catch (error) {
        console.error('Error creating tag:', error);
        throw error;
    }
};

export const editTackService = async (token, tackID, tackData) => {
    try {
        tackData['EstReg'] = 'A';
        const response = await fetch(`${API_URL}/tacks/${tackID}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(tackData),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Error al actualizar el tag');
        }

        return await response.json();
    } catch (error) {
        console.error('Error updating tag:', error);
        throw error;
    }
};