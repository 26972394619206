import React, { useState, useEffect } from 'react';
import { Grid, Button } from '@mui/material';

const colors = [
  '#E0F7FA', '#FFF9C4', '#FFECB3', '#FFCDD2', '#E1BEE7',
  '#A5D6A7', '#FFAB91', '#FF8A65', '#F48FB1', '#CE93D8',
  '#81D4FA', '#DCE775', '#C5E1A5', '#F06292', '#B39DDB',
  '#4DB6AC', '#FFF59D', '#FFCC80', '#FF7043', '#9575CD',
  '#90CAF9', '#AED581', '#FFE082', '#F50057', '#D1C4E9',
  '#2196F3', '#8BC34A', '#FFC107', '#F44336', '#9C27B0',
  '#1E88E5', '#66BB6A', '#FFCA28', '#D32F2F', '#7B1FA2',
  '#0D47A1', '#388E3C', '#F57F17', '#B71C1C', '#4A148C',
];

const ColorPicker = ({ onColorSelect, selectedColorEdit }) => {

  useEffect(() => {
    if (selectedColorEdit) {
      setSelectedColor(selectedColorEdit.newTackColor)
      setSelectedFontColor(selectedColorEdit.newTackFontColor)
    } else {
      setSelectedColor('')
      setSelectedFontColor('')
    }
  }, [selectedColorEdit]);

  const [selectedColor, setSelectedColor] = useState('');
  const [selectedFontColor, setSelectedFontColor] = useState('');

  const handleColorClick = (color) => {
    setSelectedColor(color);
    const fontColor = getContrastingColor(color);
    setSelectedFontColor(fontColor)
    onColorSelect(color, fontColor);
  };

  const getContrastingColor = (color) => {
    const r = parseInt(color.substr(1, 2), 16);
    const g = parseInt(color.substr(3, 2), 16);
    const b = parseInt(color.substr(5, 2), 16);
    return (r * 0.299 + g * 0.587 + b * 0.114) > 186 ? '#000000' : '#FFFFFF';
  };

  return (
    <Grid container spacing={1} sx={{ marginTop: '8px', justifyContent: 'center' }}>
      {colors.map((color) => (
        <Grid item xs={1.4} key={color} sx={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            onClick={() => handleColorClick(color)}
            style={{
              backgroundColor: color,
              color: selectedColor === color ? '#000000' : '#FFFFFF',
              width: '30px',
              height: '30px',
              minWidth: '30px',
              minHeight: '30px',
              borderRadius: '4px',
              border: selectedColor === color ? '2px solid #000000' : '1px solid #DDDDDD',
              boxShadow: selectedColor === color ? '0 0 5px rgba(0, 0, 0, 0.3)' : 'none',
              transition: 'all 0.2s ease-in-out',
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ColorPicker;
