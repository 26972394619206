import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useAuth } from './context/AuthContext'; 
import AppRoutes from './Routes';
import {
  Grid,
  Paper,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography
} from '@mui/material';
import LateralMenu from './components/LateralMenu';
import UserMenu from './components/menu/UserMenu'; 
import Login from './components/Login';

function App() {
  const { isAuthenticated } = useAuth();

  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <CssBaseline />
      {isAuthenticated ?  (
        <>
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" sx={{ flexGrow: 1 }}>
                Com o sem medo
              </Typography>              
              <UserMenu /> {/* Mostrar solo si está autenticado */}
            </Toolbar>
          </AppBar>
          <Grid container style={{ height: '100%' }}>
            <Grid item xs={2}>
              <Paper style={{ height: '100%' }}>
                <LateralMenu />
              </Paper>
            </Grid>
            <Grid item xs={10} style={{ padding: '16px', overflow: 'auto' }}>
              <AppRoutes />
            </Grid>
          </Grid>
        </>        
      ) : (
        <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
          <Grid item xs={12} sm={6} md={4}>
            {/* Aquí puedes colocar tu componente de login */}
            <Routes>
              <Route path="/login" element={<Login />} />
            </Routes>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default App;
