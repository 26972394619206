//const API_URL = 'http://localhost/INVESTIGACION/routes/api.php';
const API_URL = process.env.REACT_APP_API_URL;

export const fetchArticulosPorInvestigacion = async (token, investigacionID) => {    
    try {
        const response = await fetch(`${API_URL}/articulos?investigacion=${investigacionID}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });

        if (response.status === 204) {
            return [];
        }        

        const data = await response.json();

        //console.log('La data que llega', data)

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Error al obtener los artículos');
        }

        
        return data;
    } catch (error) {
        console.error('Error fetching articulos:', error);
        throw error;
    }
};

export const crearArticulo = async (token, idInvestigacion, articuloData) => {
    try {
        const response = await fetch(`${API_URL}/investigaciones/${idInvestigacion}/articulos`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(articuloData),
        });        

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Error al crear el artículo');
        }
        
        //console.log('La respuesta es...:', response)
        return await response.json();
    } catch (error) {
        console.error('Error creating artículo:', error);
        throw error;
    }
};

export const actualizarArticulo = async (token, articuloID, articuloData) => {
    try {
        const response = await fetch(`${API_URL}/articulos/${articuloID}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(articuloData),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Error al actualizar el artículo');
        }

        return await response.json();
    } catch (error) {
        console.error('Error updating artículo:', error);
        throw error;
    }
};

export const eliminarArticulo = async (token, articuloID) => {
    try {
        const response = await fetch(`${API_URL}/articulos/${articuloID}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Error al eliminar el artículo');
        }

        return await response.json();
    } catch (error) {
        console.error('Error deleting artículo:', error);
        throw error;
    }
};
