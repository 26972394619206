//const API_URL = 'http://localhost/INVESTIGACION/routes/api.php';
const API_URL = process.env.REACT_APP_API_URL;

if (process.env.NODE_ENV === 'production') {
  console.log('Estamos en producción');
} else {
  console.log('Estamos en desarrollo');
}

export const login = async (loginData) => {
  try {
    const response = await fetch(`${API_URL}/usuarios/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(loginData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Error al iniciar sesión');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error en login:', error);
    throw error;
  }
};
