import React, { useState } from 'react';
import { TextField, Button, Typography, Container, Alert, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { login } from '../services/authService';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const loginData = {
      email,
      password,
    };

    try {
      const data = await login(loginData);
      if (data.token) {
        localStorage.setItem('token', data.token);
        localStorage.setItem('refreshToken', data.refreshToken);
        setErrorMessage('');
        navigate('/investigaciones');
      } else {
        setErrorMessage('Usuario o contraseña incorrecta.');
      }
    } catch (error) {
      setErrorMessage('Error al iniciar sesión. Inténtalo nuevamente.');
    }
  };

  return (
    <Container 
      maxWidth="sm" 
      sx={{ 
        display: 'flex', 
        height: '100vh', 
        justifyContent: 'center', 
        alignItems: 'center',
        flexDirection: 'column'  // Para centrar el contenido tanto vertical como horizontalmente
      }}
    >
      <Box 
        component="form"
        onSubmit={handleSubmit} 
        sx={{ 
          padding: 4, 
          borderRadius: 2, 
          boxShadow: 3, 
          backgroundColor: 'white', 
          width: '100%', 
          maxWidth: 400, 
          textAlign: 'center' 
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Iniciar Sesión
        </Typography>
        <TextField
          fullWidth
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
          required
        />
        <TextField
          fullWidth
          label="Contraseña"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          margin="normal"
          required
        />
        {errorMessage && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {errorMessage}
          </Alert>
        )}
        <Button variant="contained" color="primary" type="submit" fullWidth sx={{ mt: 2 }}>
          Iniciar Sesión
        </Button>
      </Box>
    </Container>
  );
}

export default Login;
